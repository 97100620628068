import React, { useState, useEffect } from 'react';
import axios from 'axios';
//MUI
import { 
  Grid, 
  Box, 
  Chip, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  IconButton, 
  Switch, FormControlLabel, MenuItem, Select, InputLabel, FormControl, styled, CircularProgress, LinearProgress,
  Checkbox
 } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

//COMPONENTS
import { GLBPreview } from '../components/parts/GLBPreview';
import glbImage from '../images/graphics/glb.png'
import audioImage from '../images/graphics/audio.png'

//FIREBASE
import { auth } from '../services/firebase';


import {
  hc,
} from "../services/hashconnect";
import { 
  AccountId,
  TransferTransaction,
} from '@hashgraph/sdk';
import { extractTransactionId } from '../services/utils';

const StyledUploadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  minHeight: 100,
  marginTop: theme.spacing(2)
}));

export const Home = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const costPerKB = parseFloat(process.env.REACT_APP_INSCRIBE_PRICE_PER_KB);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [estimatedCost, setEstimatedCost] = useState(costPerKB)
  const [fileSizeKB, setFileSizeKB] = useState(null);
  const [HCSFileOnly, setHCSFileOnly] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [metadata, setMetadata] = useState({
    name: '',
    creator: '',
    description: '',
    type: '',
    properties: [],
    attributes: [],
    additionalFiles: []
  });
  const [showProperties, setShowProperties] = useState(false);
  const [showAttributes, setShowAttributes] = useState(false);
  const [showAdditionalFiles, setShowAdditionalFiles] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [buttonText, setButtonText] = useState('Start Inscription');

  useEffect(() => {
    // Check if there is a current user when the component mounts
    const currentUser = auth.currentUser;
    setUser(currentUser ? currentUser.uid : null);

    // Optionally set up a listener for auth changes if your auth context can change
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user ? user.uid : null);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const mediaTypes = [
    "image/png", "image/jpeg", "image/webp", "image/gif", "video/mp4", "audio/mp3", "audio/mpeg", "model/gltf-binary"
  ];

  const additionalFileTypes = [
    "image/png", 
    "image/jpeg", 
    "image/webp", 
    "image/gif", 
    "video/mp4", 
    "audio/mp3",
    "audio/mpeg", 
    "model/gltf-binary", 
    "application/zip",
    "image/svg+xml", // SVG images
    "video/quicktime", // MOV files
    "video/x-msvideo", // AVI files
    "video/x-matroska", // MKV files
    "audio/wav", // WAV audio files
    "audio/aac", // AAC audio files
    "application/pdf", // PDF documents
    "application/msword", // Word .doc files
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word .docx files
    "application/vnd.ms-excel", // Excel .xls files
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel .xlsx files
    "application/vnd.ms-powerpoint", // PowerPoint .ppt files
    "application/vnd.openxmlformats-officedocument.presentationml.presentation" // PowerPoint .pptx files
  ];

  const validateForm = () => {
    if (!user) {
      setError('Please Connect HashPack Wallet');
      return false;
    }

    if (!file) {
      setError('No file selected');
      return false;
    }

    if (user != "0.0.1000000" && file.size > 10485760) { // 10 MB in bytes
      setError('BETA: File size should not exceed 10 MB.');
      return false;
    }

    if (!HCSFileOnly && metadata.name.trim() === '') {
      setError('Name is required in metadata');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = async () => {

    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }
  
    setError(''); // Clear any existing errors
    
    setUploading(true);
    setButtonText('Loading Transaction...');
    

    try {
      setIsUploading(true);
      console.log(auth.currentUser.uid);
      const buyer = AccountId.fromString(auth.currentUser.uid);
      const ACCOUNT = auth.currentUser.uid;
      let fileOnly = HCSFileOnly ? "true" : "false";
      const formData = new FormData();
      formData.append('file', file);
      formData.append('account', ACCOUNT);
      formData.append('fileOnly', fileOnly)
      console.log(fileOnly)
      // Convert metadata object to JSON string
      const metadataJson = JSON.stringify(metadata);
      console.log(metadataJson)
      formData.append('metadata', metadataJson);
  
      const response = await axios.post( `${process.env.REACT_APP_API_URL}/upload`, formData, {
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress); // Assuming `setUploadProgress` updates state or displays progress
          console.log('Upload Progress: ' + progress + '%');
        }
      })
      .then(response => {
        setIsUploading(false);
        return response.data;
      })
      .catch(error => {
        setIsUploading(false);
        console.error('Error uploading file:', error);
        // Optionally update UI to show error message
      });

      console.log("UPLOAD RESPONSE: ", response)
      const transaction = TransferTransaction.fromBytes(Buffer.from(response.transactionBytes));
      const signer = hc.getSigner(buyer);

      let trans;  
        try {
          setButtonText('Complete in HashPack');
          trans = await transaction.executeWithSigner(signer);
          const transactionId = extractTransactionId(trans.transactionId);
   
          try {
            setButtonText('Confirming Transaction');

            const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/confirm`, {
                transactionId: transactionId,
                account: auth.currentUser.uid
            });

            if (response2.status === 200) {
              setUploading(false);
              navigate(`/inscription/${response2.data.uploadId}`);
            }
            
            return;
          } catch (error) {
            setButtonText("Start Inscription")
            setUploading(false);
            console.error('Error processing transaction:', error);
          }
            
        } catch (error) {
          setButtonText("Start Inscription")
          setUploading(false);
          console.log(error)
        }

      return;
    } catch (error) {
      setButtonText("Start Inscription")
      setUploading(false);
      console.error('Error calling function:', error);
      throw error;
    }
    
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFile(file);
  
    const fileSizeInKB = file.size / 1024;
    setFileSizeKB(fileSizeInKB); // Update state with file size in KB
    setEstimatedCost((fileSizeInKB * costPerKB) * 1.3); // Assuming costPerKB is defined elsewhere

    // Try to determine the file type from the input's MIME type
    let fileType = file.type;
    console.log(fileType)
    // If fileType is empty or undefined, check the file extension
    if (!fileType) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'glb') {
        fileType = 'model/gltf-binary';
      }
    }

    // Set the metadata type based on the file type or the matched type from predefined media types
    const matchedType = mediaTypes.find(type => fileType === type);
    if (matchedType) {
      setMetadata({ ...metadata, type: matchedType });
    } else {
      // If no matched type is found, optionally handle the case where the file type does not match predefined types
      setMetadata({ ...metadata, type: fileType || '' }); // Set to fileType if not matched but detected, else empty
    }

  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMetadata({ ...metadata, [name]: value });
  };

  const handlePropertyChange = (index, key, value) => {
    const newProperties = [...metadata.properties];
    newProperties[index][key] = value;
    setMetadata({ ...metadata, properties: newProperties });
  };

  const handleAttributeChange = (index, key, value) => {
    const newAttributes = [...metadata.attributes];
    newAttributes[index][key] = value;
    setMetadata({ ...metadata, attributes: newAttributes });
  };

  const handleAdditionalFileChange = (index, key, value) => {
    const newFiles = [...metadata.additionalFiles];
    // Ensure the object exists at the index
    if (!newFiles[index]) newFiles[index] = { uri: '', type: '' };
    // Update the specific key ('topicId' or 'mediaType')
    newFiles[index][key] = value;
    setMetadata({ ...metadata, additionalFiles: newFiles });
  };

  const addPropertyField = () => {
    setMetadata({ ...metadata, properties: [...metadata.properties, { key: '', value: '' }] });
  };

  const addAttributeField = () => {
    setMetadata({ ...metadata, attributes: [...metadata.attributes, { trait_type: '', value: '' }] });
  };

  const addAdditionalFileField = () => {
    setMetadata({
        ...metadata, 
        additionalFiles: [...metadata.additionalFiles, { uri: '', type: '' }]
    });
  };

  const removePropertyField = (index) => {
    const newProperties = [...metadata.properties];
    newProperties.splice(index, 1);
    setMetadata({ ...metadata, properties: newProperties });
    if (newProperties.length === 0) {
      setShowProperties(false);
    }
  };

  const removeAttributeField = (index) => {
    const newAttributes = [...metadata.attributes];
    newAttributes.splice(index, 1);
    setMetadata({ ...metadata, attributes: newAttributes });
    if (newAttributes.length === 0) {
      setShowAttributes(false);
    }
  };

  const removeAdditionalFileField = (index) => {
    const newFiles = [...metadata.additionalFiles];
    newFiles.splice(index, 1);
    setMetadata({ ...metadata, additionalFiles: newFiles });
    if (newFiles.length === 0) {
      setShowAdditionalFiles(false);
    }
  };

  const toggleProperties = (event) => {
    const isChecked = event.target.checked;
    setShowProperties(isChecked);
    if (isChecked) {
      if (metadata.properties.length === 0) {
        addPropertyField(); // Add one empty property field if none exists
      }
    } else {
      setMetadata({ ...metadata, properties: [] }); // Reset properties when toggled off
    }
  };

  const toggleAttributes = (event) => {
    const isChecked = event.target.checked;
    setShowAttributes(isChecked);
    if (isChecked) {
      if (metadata.attributes.length === 0) {
        addAttributeField(); // Add one empty property field if none exists
      }
    } else {
      setMetadata({ ...metadata, attributes: [] }); // Reset properties when toggled off
    }
  };

  const toggleAdditionalFiles = (event) => {
    const isChecked = event.target.checked;
    setShowAdditionalFiles(isChecked);
    if (isChecked) {
      if (metadata.additionalFiles.length === 0) {
        addAdditionalFileField(); // Add one empty file field if none exists
      }
    } else {
      setMetadata({ ...metadata, additionalFiles: [] }); // Reset additional files when toggled off
    }
  };

  return (
    <Grid container spacing={2} padding={2}>

      <Grid item xs={12} md={6}>
        <input
          accept="image/*,video/*,audio/*,.glb"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
          disabled={isUploading} 
        />
        <StyledUploadBox style={{ position: 'relative', background: 'rgb(0,0,0,0.3)', marginBottom: 30 }}>
          <input
            accept="image/*,video/*,audio/*,.glb"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
            disabled={isUploading}
          />
          <label htmlFor="raised-button-file">
            <Button  variant="contained" component="span" disabled={isUploading || uploading} sx={{ mt: 3, mb: 3 }}> 
              Select File
            </Button>
          </label>
          {file && (
                <>
                  <Typography variant="subtitle1" sx={{ mb: 2, color: "#fff", fontWeight: 'bold', fontSize: '13px', maxWidth: 300, textAlign: "center" }} gutterBottom>{file.name}</Typography>
                  <Chip
                    label={<Typography variant="subtitle1">File Size: {fileSizeKB.toFixed(2)} KB</Typography>}
                    variant="outlined"
                    sx={{
                      mt: 1,
                      mb: 1.5,
                      color: '#d36eff', 
                      borderColor: '#d36eff', 
                      borderRadius: '16px',
                      fontSize: '0.875rem',  
                      fontWeight: 'bold'
                    }}
                  />                       
                  <Chip
                    label={<Typography variant="subtitle1">Estimated Cost: {estimatedCost.toFixed(2)} ℏ</Typography>}
                    variant="outlined"
                    sx={{
                      color: '#69ff66', 
                      borderColor: '#69ff66', 
                      borderRadius: '16px',
                      fontSize: '0.875rem',  
                      fontWeight: 'bold'
                    }}
                  />  
                  <Typography sx={{fontSize: "11px", mt: 1, color: "#d3d3d3"}}>{costPerKB} ℏ / per message</Typography>            
                </>
              )}
              {file && (
              <Box sx={{ mt: 5, mb: 5, textAlign: 'center', p: 2, border: 'rgba(255, 255, 255,0.5) 1px dashed', borderRadius: '5px', position: 'relative' }}>
                {/* Conditional rendering of the LinearProgress loader */}
                {isUploading && (
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '4px', // Adjust the height of the loader
                  }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{
                      width: '100%',
                      height: '4px',
                      borderRadius: '5px',
                      '& .MuiLinearProgress-bar': { // Targeting the bar directly
                        backgroundColor: '#92ff2b' // Example: a green color
                      },
                      backgroundColor: 'grey.300' // Background color of the bar track
                    }}
                  /> 
                  </Box>
                )}

                {/* File preview */}
                {file.type.startsWith('image') && <img alt="Preview" src={URL.createObjectURL(file)} style={{ maxWidth: '100%', maxHeight: '250px', borderRadius: '5px', marginTop: '5px' }} />}
                {file.type.startsWith('video') && <video controls src={URL.createObjectURL(file)} style={{ maxWidth: '100%', maxHeight: '250px', marginTop: '5px' }} />}
                {file && file.name.endsWith('.glb') && <img src={glbImage} width={200} height={200} />}
                {file.type.startsWith('audio') && <img src={audioImage} width={200} height={200} />}
              </Box>
            )}

        </StyledUploadBox>
      </Grid>


      <Grid item xs={12} md={6}>
        <FormControlLabel
          sx={{mt: 1}}
          control={
            <Checkbox
              disabled={uploading}
              color="primary" 
              checked={HCSFileOnly}
              onChange={() => setHCSFileOnly(!HCSFileOnly)}
            />
          }
          label={<Typography variant="body1" style={{ fontSize: '13px' }}>HCS-1 File Only</Typography>}
        />
        {!HCSFileOnly ? (
          <>
        <TextField disabled={uploading} fullWidth label="Name*" name="name" value={metadata.name} onChange={handleChange} margin="normal" />
        <TextField disabled={uploading} fullWidth label="Creator" name="creator" value={metadata.creator} onChange={handleChange} margin="normal" />
        <TextField disabled={uploading} fullWidth label="Description" name="description" value={metadata.description} onChange={handleChange} margin="normal" />
        <FormControl fullWidth margin="normal">
          <InputLabel>Type of File</InputLabel>
          <Select
            disabled={uploading}
            name="type"
            value={metadata.type}
            label="Type of File"
            onChange={handleChange}
          >
            {mediaTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{mt: 2}}
              control={<Switch checked={showProperties} onChange={toggleProperties} />}
              label="Add Properties"
            />
            {showProperties && metadata.properties.map((prop, index) => (
              <Box key={index} style={{ padding: '10px', margin: '10px 0', background: "rgba(0,0,0,0.3)", borderRadius: 5 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <TextField fullWidth disabled={uploading} label="Key" value={prop.key} onChange={(e) => handlePropertyChange(index, 'key', e.target.value)} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField fullWidth disabled={uploading} label="Value" value={prop.value} onChange={(e) => handlePropertyChange(index, 'value', e.target.value)} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removePropertyField(index)}><RemoveCircleOutlineIcon /></IconButton>
                    {index === metadata.properties.length - 1 && (
                      <IconButton onClick={addPropertyField}><AddCircleOutlineIcon /></IconButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{mt: 2}}
              control={<Switch checked={showAttributes} onChange={toggleAttributes} />}
              label="Add Attributes"
            />
            {showAttributes && metadata.attributes.map((prop, index) => (
              <Box key={index} style={{ padding: '10px', margin: '10px 0', background: "rgba(0,0,0,0.3)", borderRadius: 5 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <TextField disabled={uploading} fullWidth label="Trait_Type" value={prop.trait_type} onChange={(e) => handleAttributeChange(index, 'trait_type', e.target.value)} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField disabled={uploading} fullWidth label="Value" value={prop.value} onChange={(e) => handleAttributeChange(index, 'value', e.target.value)} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeAttributeField(index)}><RemoveCircleOutlineIcon /></IconButton>
                    {index === metadata.attributes.length - 1 && (
                      <IconButton onClick={addAttributeField}><AddCircleOutlineIcon /></IconButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{mt: 2}}
              control={<Switch checked={showAdditionalFiles} onChange={toggleAdditionalFiles} />}
              label="Add Additional Files"
            />
            {showAdditionalFiles && metadata.additionalFiles.map((file, index) => (
              <Paper key={index} style={{ padding: '10px', margin: '10px 0', background: "rgba(0,0,0,0.3)" , borderRadius: 5 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={7}>
                    <TextField disabled={uploading} fullWidth label="HCS-1 Topic ID" value={file.topicId} onChange={(e) => handleAdditionalFileChange(index, 'uri', e.target.value)} />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel>Media Type</InputLabel>
                      <Select
                        disabled={uploading}
                        value={file.type || ''}
                        label="Media Type"
                        onChange={(e) => handleAdditionalFileChange(index, 'type', e.target.value)}
                      >
                        {additionalFileTypes.map((type, idx) => (
                          <MenuItem key={idx} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeAdditionalFileField(index)}><RemoveCircleOutlineIcon /></IconButton>
                    {index === metadata.additionalFiles.length - 1 && (
                      <IconButton onClick={addAdditionalFileField}><AddCircleOutlineIcon /></IconButton>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>

        </Grid>
        
        </>
        ):(
          <>
          <Typography sx={{fontSize: "13px", pr: 2}}>
                  Checking this box creates a standalone HCS-1 file without any attached metadata. 
                  This option is useful if you want to use the file separately, such as adding it to the 
                  'Additional Files' section of another NFT's metadata or directly inserting it into the 
                  'Image' field of an NFT's metadata. If you leave this box unchecked, you can enter metadata, 
                  and the system will upload both the file as HCS-1 and the metadata as a separate HCS-1 file which
                  can be used to mint onto a token. 
          </Typography>

          <FormControl fullWidth margin="normal">
          <InputLabel>Type of File</InputLabel>
          <Select
            disabled={uploading}
            name="type"
            value={metadata.type}
            label="Type of File"
            onChange={handleChange}
          >
            {mediaTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
          </FormControl>
          </>
        )}

                  
        <Grid item xs={12}>
          <Button
            sx={{width: "100%", mt: 4, mb: 1}}
            variant="contained"
            color="primary"
            disabled={uploading}
            onClick={handleSubmit}
          >
            {buttonText}
          </Button>
        </Grid>

        <Grid item xs={12}>
          {error && (
            <Typography align='center' color="error" sx={{ mb: 1 }}>
              {error}
            </Typography>
          )}
        </Grid>
        <Box sx={{mb: 10}}></Box>

      </Grid>
    </Grid>
  );
};
