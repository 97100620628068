import React, { useState } from 'react';
import { Paper, Tabs, Tab, Typography, Switch, FormControlLabel, Checkbox, TextField, Button } from '@mui/material';
import CreateTokenForm from './CreateTokenForm';
import MintExistingForm from './MintExistingForm';
import TokenTable from './TokenTable';

const MintForms = ({ uploadId, metadataTopicId }) => {
  const [tabValue, setTabValue] = useState(0);
  const [supplyKey, setSupplyKey] = useState("");
  const [tokenId, setTokenId] = useState("");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Callback function to switch tab
  const handleTabSwitch = () => {
    setTabValue(1); // Switch to the second tab (index 1)
  };

  const handleKeyPass = (keyValues) => {
    console.log(keyValues)
    setSupplyKey(keyValues);
  }

  const handleTokenPass = (keyValues) => {
    setTokenId(keyValues);
  }

  return (
    <>
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Tabs value={tabValue} onChange={handleChange} centered indicatorColor="secondary">
        <Tab label="Create New Token" style={{ color: tabValue === 0 ? '#fff' : '#5b00ff', textDecoration: "none" }} />
        <Tab label="Mint Onto Token" style={{ color: tabValue === 1 ? '#fff' : '#5b00ff' }} />
      </Tabs>
      {tabValue === 0 && (
        <CreateTokenForm onTokenCreated={handleTabSwitch} uploadId={uploadId} handleKeyPass={handleKeyPass} handleTokenPass={handleTokenPass}/>
      )}
      {tabValue === 1 && (
        <MintExistingForm metadataTopicId={metadataTopicId} supplyKeyProp={supplyKey} tokenIdProp={tokenId}/>
      )}
    </Paper>
    {tabValue === 1 && (
    <TokenTable />
    )}
    </>
  );
};

export default MintForms;
