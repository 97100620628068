import React, { useEffect, useState } from 'react';
import { useCallback } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged, updateProfile } from "firebase/auth";
import {
  getConnectedAccountIds,
  hc,
  hcInitPromise,
} from "../../services/hashconnect";
import { actions, AppStore } from "../../store";
import { fetchUserData, verifyAndCreateToken } from '../../services/firebase/firebaseFunctions';
import { userActions } from '../../store/index';


export const HashConnectClient = () => {
  const dispatch = useDispatch();
  const syncWithHashConnect = useCallback(() => {
    const connectedAccountIds = getConnectedAccountIds();

    
    if (connectedAccountIds.length > 0) {
      dispatch(
        actions.hashconnect.setAccountIds(
          connectedAccountIds.map((o) => o.toString())
        )
      );
      dispatch(actions.hashconnect.setIsConnected(true));
      dispatch(actions.hashconnect.setPairingString(hc.pairingString ?? ""));
    } else {
      dispatch(actions.hashconnect.setAccountIds([]));
      dispatch(actions.hashconnect.setIsConnected(false));
      dispatch(actions.hashconnect.setPairingString(hc.pairingString ?? ""));
    }
  }, [dispatch]);

  syncWithHashConnect();
  hcInitPromise.then(() => {
    syncWithHashConnect();
  });
  hc.pairingEvent.on(() => {
    syncWithHashConnect();
  });
  hc.disconnectionEvent.on(() => {
    syncWithHashConnect();
  });
  hc.connectionStatusChangeEvent.on(() => {
    syncWithHashConnect();
  });
  return null;
};

export const HashConnectConnectButton = () => {
  const { isConnected, accountIds: connectedAccountIds } = useSelector((state) => state.hashconnect);
  const [authenticationInitiated, setAuthenticationInitiated] = useState(false); // New state to track if authentication has been initiated
  
  const [loginProgress, setLoginProgress] = useState(0);
  const [awaitingLogin, setAwaitingLogin] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = getAuth();
  
  const handleOpenMenu = (event) => {

    if (!isConnected) {
      // Trigger wallet connection process
      hc.openPairingModal();
    }else if(isConnected && !auth.currentUser){
      setAnchorEl(event.currentTarget);
    }else{
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
        // Disconnect the wallet using HashConnect
        await hc.disconnect();
        dispatch(actions.hashconnect.setIsConnected(false));
        dispatch(actions.hashconnect.setAccountIds([]));

        // Sign out from Firebase Auth
        const auth = getAuth();
        await signOut(auth);

        // Close the menu
        handleCloseMenu();
      
        //logout
        setAuthenticationInitiated(false);
        // Optionally, navigate the user to the login page or home page after logout
        navigate('/'); // Adjust the path as per your routing setup
    } catch (error) {
        console.error('Error during logout:', error);
        // Handle any errors that occur during logout
    }
};

  useEffect(() => {
    const authenticateUser = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        return; // Skip if already authenticated
      }
      if (isConnected && connectedAccountIds.length > 0 && !authenticationInitiated) {
        setAuthenticationInitiated(true); // Set to true to prevent re-initiating authentication
        try {
          // Authentication and signing logic...
          let signer = hc.getSigner(connectedAccountIds[0]);
          let signature = await signer.sign(["Hashinals.io"]);
          setLoginProgress(0)
          setAwaitingLogin(true);
          const customTokenData = await verifyAndCreateToken(signature, connectedAccountIds[0]);
          setLoginProgress(100)
          setAwaitingLogin(false);
          const signInData = await signInWithCustomToken(getAuth(), customTokenData.token);
          
          navigate('/');
        } catch (error) {
          console.error('Error during authentication:', error);
          return;
        }
      }
    };

    authenticateUser();
  }, [isConnected, connectedAccountIds, navigate, authenticationInitiated]);


    // Handle counting login progress
  useEffect(() => {
    let interval;
    if (awaitingLogin && loginProgress < 90) {
      interval = setInterval(() => {
        setLoginProgress(loginProgress => loginProgress + 1);
      }, 100); // Adjust time interval as needed
    } 
    return () => clearInterval(interval); // Cleanup on unmount
  }, [awaitingLogin, loginProgress]);

  
  return (
    <Box>
      <Button
        sx={{
          mr: 1,
          color: '#ffffff',
          backgroundColor: "#282940",
          "&:hover": {
            backgroundColor: "#3f3f5f", // Change the background color on hover
          },
        }}
        color="inherit"
        variant="contained"
        onClick={handleOpenMenu}
      >
        {awaitingLogin ? (
          <>
            <Box sx={{ width: '100%', minWidth: '100px', minHeight: '23px'}}>
              <LinearProgress color="teal" value={loginProgress} variant="determinate" sx={{height: '8px', borderRadius: '5px', marginTop: '7px'}} />
            </Box>
          </>
        ):(
          <>
            {isConnected ? connectedAccountIds[0] : 'Connect'}
          </>
        )}
      </Button>
        {isConnected && auth.currentUser ? (
            <>
              <Menu 
              PaperProps={{
                sx: {
                  "& .MuiPaper-root": {
                    backgroundColor: "#3f3f5f", // Change the background color of the menu
                  },
                },
              }}
              id="account-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "#282940", // Change the background color on hover
                    },
                  }} onClick={() => { navigate('/profile'); handleCloseMenu(); }}>
                  <AccountBoxIcon fontSize='small' sx={{ mr: 1 }} />Profile</MenuItem>
                {/* <MenuItem sx={{
                  "&:hover": {
                    backgroundColor: "#282940", // Change the background color on hover
                  },
                  }} onClick={() => { navigate('/settings'); handleCloseMenu(); }}>
                  <SettingsApplicationsIcon fontSize='small' sx={{ mr: 1 }} />Settings</MenuItem> */}
                <MenuItem
                  sx={{
                    color: "#ff5454",
                    "&:hover": {
                      backgroundColor: "#282940", // Change the background color on hover
                    },
                  }} onClick={handleLogout}>
                  <ExitToAppIcon fontSize='small' sx={{ mr: 1 }} />Logout
                </MenuItem>
              </Menu>
            </>
        ):(
            <>
            <Menu id="account-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
              <MenuItem onClick={handleLogout} sx={{color: "#ff5454"}}>
              <ExitToAppIcon fontSize='small' sx={{ mr: 1 }} />Disconnect
              </MenuItem>
            </Menu>
            </>
        )}
    </Box>
  );
};
