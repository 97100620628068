import React, { useState, useEffect } from 'react';
import { db, auth } from '../services/firebase';
import { query, collection, where, orderBy, startAfter, startAt, endBefore, limitToLast, limit, getDocs } from 'firebase/firestore';
import {Chip, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination, Button, Paper, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';


export const Profile = () => {
  const navigate = useNavigate();
  const [inscriptions, setInscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);


  const fetchInscriptions = async (newPage, rows) => {
    setLoading(true);
    const currentUser = auth.currentUser.uid;

    let ref = collection(db, "uploads");
    let q;

    if (newPage === 0) {  // Initial load or reset to first page
      q = query(ref, 
                where("account", "==", currentUser), 
                where("paid", "==", true),  // Ensure record is marked as paid
                orderBy("timestamp", "desc"), 
                limit(rows));
    } else if (newPage > page && lastDoc) {  // Moving forward
      q = query(ref, 
                where("account", "==", currentUser), 
                where("paid", "==", true),  // Ensure record is marked as paid
                orderBy("timestamp", "desc"), 
                startAfter(lastDoc), 
                limit(rows));
    } else if (newPage < page && firstDoc) {  // Moving backward
      q = query(ref, 
                where("account", "==", currentUser), 
                where("paid", "==", true),  // Ensure record is marked as paid
                orderBy("timestamp", "desc"), 
                endBefore(firstDoc), 
                limitToLast(rows));
    }

    try {
      const documentSnapshots = await getDocs(q);
      const docs = documentSnapshots.docs;
      if (docs.length > 0) {
        setInscriptions(docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLastDoc(docs[docs.length - 1]);
        setFirstDoc(docs[0]);
        setHasMore(docs.length === rows);  // Check if the number of docs returned is less than requested
        setPage(newPage);
      } else {
        setHasMore(false);  // No more entries available
      }
    } catch (error) {
      console.error("Error fetching inscriptions: ", error);
      setHasMore(false);  // Assume no more entries on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInscriptions(page, rowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    fetchInscriptions(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setLastDoc(null); // Resetting the pagination cursors
    setFirstDoc(null);
    setPage(0); // Reset to the first page
    fetchInscriptions(0, newRowsPerPage);
  };

  const handleChipClick = (uid) => {
    navigate(`/inscription/${uid}`);
  };


  return (
    <Box sx={{m: 2}}>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {loading ? <CircularProgress /> : (
        <>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Topic ID</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inscriptions.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      {row.fileOnly === true ? (
                        <Chip
                          sx={{color: "#fff", cursor: 'pointer',}}
                          variant='outlined'
                          color='warning'
                          label={`HCS-1 FILE`}
                          onClick={() => handleChipClick(row.id)}  // Assuming row.uid is the unique identifier for navigation
                        />      
                      ) : (
                        <Chip
                          sx={{color: "#fff", cursor: 'pointer',}}
                          variant='outlined'
                          color='secondary'
                          label={`HASHINAL`}
                          onClick={() => handleChipClick(row.id)}  // Assuming row.uid is the unique identifier for navigation
                        /> 
                      )}
                    </TableCell>
                    <TableCell align="center">{row.name ? row.name : "--"}</TableCell>
                    <TableCell align="center">
                      {row.mediaTopicId ? (
                        <a target='_blank' style={{ color: '#e3e3e3', textDecoration: 'none', ml: 1 }} href={`https://tier.bot/api/hashinals-cdn/${row.metadataTopicId ? row.metadataTopicId : row.mediaTopicId}?network=${process.env.REACT_APP_NETWORK}`}>
                        {row.metadataTopicId ? row.metadataTopicId : row.mediaTopicId} <OpenInNewIcon fontSize='xs' sx={{pt: 0.5}}/>
                        </a>
                      ):(
                        <Typography sx={{fontSize: "12px"}}>Not Found</Typography>
                      )}
                    </TableCell>
  
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="right" component="th" scope="row">                     
                     {moment(new Date(row.timestamp.seconds * 1000)).fromNow()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={-1} // Since total size is unknown, set to -1
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{ disabled: !hasMore }}  // Disable forward button if no more entries
          />
        </>
      )}
    </Paper>
    </Box>
  );
};
