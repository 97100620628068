// src/contexts/AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { auth } from '../services/firebase'; // Adjust the import path according to your Firebase configuration
import { useSelector } from 'react-redux';
import { AppStore } from '../store'; // Adjust the import path as necessary

interface AuthContextType {
  currentUser: any; // Adjust according to your user model or use Firebase User type
  isConnectedToWallet: boolean; // Indicates if the user's wallet is connected
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined); // Exported here

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<any>(null); // Adjust the type as necessary
  const { isConnected } = useSelector((state: AppStore) => state.hashconnect); // Assuming you store HashConnect's connected status in Redux

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return unsubscribe; // Unsubscribe on cleanup
  }, []);

  const value = {
    currentUser,
    isConnectedToWallet: isConnected, // This assumes isConnected properly reflects the wallet's connection status
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};