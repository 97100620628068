import { AppBar, Toolbar, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <AppBar position="relative" color="secondary">
      <Toolbar>

      </Toolbar>
    </AppBar>
  );
};
