import { Box, Stack, useTheme } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from '../../components/hashconnect/privateroute'; // Adjust path as necessary

import { Home } from "../../pages/Home";
import { Inscription } from "../../pages/Inscription";
import { Profile } from "../../pages/Profile";
import { NotFound } from "../../pages/404";

import { Footer } from "./Footer";
import { Layout } from "./Layout";
import { Navbar } from "./Navbar";


export const Router = () => {
  const theme = useTheme();

  const style = {
    animation: 'gradientAnimation 15s ease infinite', // Apply the animation
    background: 'linear-gradient(45deg, #00bbff, #7c00d4)', // Define the gradient
    backgroundSize: '150% 150%', // Increase the background size to see the moving effect
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 0px',
  };

  return (
    <BrowserRouter>
      <Stack minHeight="100vh">
        <Navbar />
        <Box flex={1} sx={style}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/inscription/:uploadId" element={<Inscription />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>

            {/* Wrap the private routes within a PrivateRoute component */}
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Layout />}>

                <Route path="/profile" element={<Profile />} />
                {/* You can add more private routes here */}
              </Route>
            </Route>
          </Routes>
        </Box>
        <Footer />
      </Stack>
    </BrowserRouter>
  );
};