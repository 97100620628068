import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Divider, 
    Typography, 
    Switch, 
    FormControlLabel, 
    Checkbox, 
    TextField, 
    Button, 
    IconButton, 
    Slider,
    Grid,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Paper,
    Tooltip
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
    Hbar, 
    PrivateKey, 
    TokenCreateTransaction, 
    TokenType, 
    AccountId, 
    TransactionId, 
    TokenSupplyType, 
    Timestamp, 
    CustomRoyaltyFee, 
    CustomFixedFee,
    TokenMintTransaction
} from '@hashgraph/sdk';
import { auth, db } from '../../services/firebase';
import {
    executeTransaction,
    signTransaction,
    hc,
  } from "../../services/hashconnect";
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const MintExistingForm = ({ tokenIdProp, supplyKeyProp, metadataTopicId }) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser ? auth.currentUser.uid : "");
  const [tokenId, setTokenId] = useState(tokenIdProp || '');
  const [supplyKey, setSupplyKey] = useState(supplyKeyProp || '');
  const [showKey, setShowKey] = useState(false);
  const [metadata, setMetadata] = useState(`hcs://1/${metadataTopicId || ''}`);
  const [quantity, setQuantity] = useState(1);
  const [isMetadataEditable, setIsMetadataEditable] = useState(false);

  const handleToggleShowKey = () => {
    setShowKey(!showKey);
  };

  const handleQuantityChange = (event, newValue) => {
    setQuantity(newValue);
  };

  const handleSubmit = () => {
    console.log('Submit', { tokenId, supplyKey, metadata, quantity });
    // Here you would integrate with the backend or smart contract to mint the tokens
  };

  return (
    <Box padding={2}>
      <Typography variant="h6" sx={{mb: 1}}>Mint Existing Token</Typography>
      
      <TextField
        fullWidth
        color="secondary" 
        label="Token ID"
        variant="outlined"
        margin="normal"
        inputProps={{style: {fontSize: 13}}}  
        InputLabelProps={{style: {fontSize: 13}}} 
        value={tokenId}
        onChange={e => setTokenId(e.target.value)}
      />
      <Box sx={{mt: 1, mb: 1}}>
        <FormControlLabel
            control={<Switch sx={{ml: 1}} color="secondary" size="small"  checked={showKey} onChange={handleToggleShowKey} />}
            label={<Typography variant="body1" style={{ fontSize: '13px' }}>Reveal Supply Key</Typography>}
        />
        <TextField
            fullWidth
            color="secondary" 
            label="Supply Key"
            variant="outlined"
            margin="normal"
            type={showKey ? 'text' : 'password'}
            value={supplyKey}
            inputProps={{style: {fontSize: 13}}}  
            InputLabelProps={{style: {fontSize: 13}}} 
            onChange={e => setSupplyKey(e.target.value)}
        />
       </Box>
      <FormControlLabel
        control={
          <Checkbox
            color="secondary" 
            checked={isMetadataEditable}
            onChange={() => setIsMetadataEditable(!isMetadataEditable)}
          />
        }
        label={<Typography variant="body1" style={{ fontSize: '13px' }}>Edit Metadata</Typography>}
      />
      <TextField
        fullWidth
        sx={{mb: 2}}
        color="secondary" 
        label="Metadata HCS Topic ID"
        variant="outlined"
        margin="normal"
        inputProps={{style: {fontSize: 13}}}  
        InputLabelProps={{style: {fontSize: 13}}} 
        value={metadata}
        onChange={e => setMetadata(e.target.value)}
        disabled={!isMetadataEditable}
      />
    <Grid container sx={{mt: 1, mb: 2}}>
      <Grid item xs={12} sm={8} md={6}>
            <Typography sx={{fontSize: "13px", mb: 1}}>Quantity <span style={{color: "#7a7385"}}>(Max: 10 Per Transaction)</span></Typography>
            <Slider
                sx={{ml: 1}}
                value={quantity}
                onChange={handleQuantityChange}
                aria-labelledby="quantity-slider"
                valueLabelDisplay="auto"
                step={1}
                marks={true}
                min={1}
                max={10}
                color="secondary"
            />
      </Grid>
      </Grid>
      <Grid container sx={{mt: 4, mb: 2}}>
        <Grid item xs={12} sm={4} md={3}>
        <Button variant="contained" color="secondary" sx={{width: "100%"}} 
        onClick={async () => {

                let cidArray = [];
                for (let iteration = 0; iteration < quantity; iteration++) {
                    let cidIt = Buffer.from(metadata);
                    cidArray.push(cidIt)
                }

                const account = AccountId.fromString(currentUser ? currentUser : "");
                let tokenMintTransaction = new TokenMintTransaction()
                .setTokenId(tokenId)
                .setMetadata(cidArray)
                .setMaxTransactionFee(new Hbar(20))

                tokenMintTransaction.setNodeAccountIds([AccountId.fromString("0.0.3")])
                tokenMintTransaction.setTransactionId(TransactionId.generate(account));
                const frozenTransaction = tokenMintTransaction.freeze();
                frozenTransaction.sign(PrivateKey.fromString(supplyKey));
    
                try {
                    const executeResult = await executeTransaction(
                        account,
                        frozenTransaction
                    );
                    
                    if(executeResult) {
                    
                    }

                    console.log({ executeResult });
                } catch(err) {
                    console.log(err)
                }
            }}
            >
            Mint
        </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MintExistingForm;