import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    userInfo: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.userInfo = action.payload;
    },
    clearUser: (state) => {
      state.isAuthenticated = false;
      state.userInfo = null;
    },
  },
});

const hashconnectSlice = createSlice({
  name: "hashconnectSlice",
  initialState: {
    isConnected: false,
    accountIds: [] as string[],
    pairingString: "",
  },
  reducers: {
    setIsConnected: (state, action: { payload: boolean }) => {
      state.isConnected = action.payload;
    },
    setAccountIds: (state, action: { payload: string[] }) => {
      state.accountIds = action.payload;
    },
    setPairingString: (state, action: { payload: string }) => {
      state.pairingString = action.payload;
    },
  },
});

// config the store
export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    hashconnect: hashconnectSlice.reducer,
  },
});

export type AppStore = ReturnType<typeof store.getState>;

export const actions = {
  user: userSlice.actions,
  hashconnect: hashconnectSlice.actions,
};
