import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Divider, 
    Typography, 
    Switch, 
    FormControlLabel, 
    Checkbox, 
    TextField, 
    Button, 
    IconButton, 
    Grid,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Paper,
    Tooltip
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
    Hbar, 
    PrivateKey, 
    TokenCreateTransaction, 
    TokenType, 
    AccountId, 
    TransactionId, 
    TokenSupplyType, 
    Timestamp, 
    CustomRoyaltyFee, 
    CustomFixedFee
} from '@hashgraph/sdk';
import { auth, db } from '../../services/firebase';
import {
    executeTransaction,
    signTransaction,
    hc,
  } from "../../services/hashconnect";
import { doc, addDoc, collection } from 'firebase/firestore';



const CreateTokenForm = ({onTokenCreated, uploadId, handleKeyPass, handleTokenPass}) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser ? auth.currentUser.uid : "");
  const [showKeys, setShowKeys] = useState(false);
  const [tokenId, setTokenId] = useState(null)
  const [addRoyalties, setAddRoyalties] = useState(false);
  const [keyValues, setKeyValues] = useState({
    admin: '',
    supply: '',
    freeze: '',
    wipe: '',
    feeSchedule: '',
    pause: '',
    metadata: ''
  });
  const [tokenDetails, setTokenDetails] = useState({
    name: '',
    symbol: '',
    supply: 1,
  });
  const [royaltyFields, setRoyaltyFields] = useState([
    { accountId: '', percentage: '' }
  ]);

  useEffect(() => {
    // Generate supply key on load
    generateRandomKey('supply');
  }, []);

  useEffect(() => {
    handleKeyPass(keyValues.supply)
  }, [keyValues.supply])

  useEffect(() => {
    handleTokenPass(tokenId)
  }, [tokenId])

  const handleKeyCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      generateRandomKey(name);
    } else {
      setKeyValues((prevKeyValues) => ({
        ...prevKeyValues,
        [name]: '',
      }));
    }
  };

  const generateRandomKey = (keyName) => {
    const randomKey = PrivateKey.generateED25519();
    setKeyValues((prevKeyValues) => ({
      ...prevKeyValues,
      [keyName]: randomKey.toString(),
    }));
  };

  const handleKeysToggle = () => {
    setShowKeys(!showKeys);
  };

  const handleAddRoyaltiesToggle = () => {
    setAddRoyalties(!addRoyalties);
    if (!addRoyalties) {
      setRoyaltyFields([{ accountId: '', percentage: '' }]);
    }
  };

  const handleAddRoyaltyField = () => {
    if (royaltyFields.length < 10) {
      setRoyaltyFields([...royaltyFields, { accountId: '', percentage: '' }]);
    }
  };

  const handleDeleteRoyaltyField = (index) => {
    const updatedFields = [...royaltyFields];
    updatedFields.splice(index, 1);
    setRoyaltyFields(updatedFields);
  };

  const handleRoyaltyFieldChange = (index, key, value) => {
    const updatedFields = [...royaltyFields];
    if(key === "percentage"){
        updatedFields[index][key] = Math.abs(value);
    }else{
        updatedFields[index][key] = value;
    }
    setRoyaltyFields(updatedFields);
  };

  const downloadKeys = () => {
    const keysBlob = new Blob([JSON.stringify(keyValues, null, 2)], { type: 'application/json' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(keysBlob);
    downloadLink.download = 'keys.json';
    downloadLink.click();
  };

  // Function to update Firestore document
  const updateTokens = async (tokenData) => {
    // Reference to the collection where the document should be added
    const tokensCollection = collection(db, `users/${currentUser}/tokens`);
  
    try {
      const newDocRef = await addDoc(tokensCollection, {
        ...tokenData // Spread the tokenData directly as the content of the new document
      });
      //console.log("Document successfully added with token data. Document ID:", newDocRef.id);
    } catch (error) {
      //console.error("Error adding document: ", error);
    }
  };
  const handleChange = (event) => {
    const { id, value } = event.target;
  
    // If it's the supply field, sanitize the value to ensure it's a non-zero positive integer
    if (id === 'supply') {
      // If the value is empty or not a valid number, default it to 1
      const sanitizedValue = value === '' || /^\d+$/.test(value) ? value : '1';
  
      // Check if the value is empty, not zero, and doesn't start with zero
      const isNonZero = sanitizedValue === '' || (parseInt(sanitizedValue) !== 0 && sanitizedValue[0] !== '0');
      const clean = sanitizedValue.replace('-', '');
  
      // If the value is either empty or a valid number and is not zero or doesn't start with zero, update the state
      if (isNonZero) {
        setTokenDetails((prevDetails) => ({
          ...prevDetails,
          [id]: clean,
        }));
      }
    } else {
      // For name and symbol fields, directly update the state with the entered value
      setTokenDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Combine all form fields into an object to be submitted
    const formData = {
      ...tokenDetails,
      keys: keyValues,
      royalties: addRoyalties ? royaltyFields : [],
    };
    // Handle form submission
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>Create New Token</Typography>
        <TextField 
        inputProps={{style: {fontSize: 13}}}
        InputLabelProps={{style: {fontSize: 13}}} 
        color="secondary" 
        id="name" 
        label="Token Name" 
        value={tokenDetails.name}
        variant="outlined" 
        onChange={handleChange}
        fullWidth
        sx={{mt: 3, mb: 1}}
        />
        <TextField 
        id="symbol"
        inputProps={{style: {fontSize: 13}}}  
        InputLabelProps={{style: {fontSize: 13}}} 
        color="secondary"
        label="Token Symbol" 
        value={tokenDetails.symbol}
        variant="outlined" 
        onChange={handleChange}
        fullWidth 
        sx={{mt: 1, mb: 1}}
        />
        <TextField 
        inputProps={{style: {fontSize: 13}}} 
        InputLabelProps={{style: {fontSize: 13}}} 
        color="secondary" 
        id="supply" 
        label="Token Supply" 
        value={tokenDetails.supply}
        variant="outlined" 
        onChange={handleChange}
        fullWidth
        sx={{mt: 1, mb: 1}}
        />

      <Box sx={{mt: 2, mb: 3}}> 
        <Box sx={{mb: 1}}> 
            <FormControlLabel
                control={<Switch color="secondary" size='small' checked={showKeys} onChange={handleKeysToggle} sx={{ml: 1}} />}
                label={<Typography variant="body1" style={{ fontSize: '13px' }}>Reveal Keys</Typography>}
            />
        </Box>

        {Object.entries(keyValues).map(([key, value], index) => (
            <div key={index}>
            <FormControlLabel
                control={<Checkbox color="secondary" name={key} checked={!!value} onChange={handleKeyCheckboxChange} disabled={key === 'supply'}/>}
                label={<Typography variant="body1" style={{ fontSize: '13px' }}>Include {key.charAt(0).toUpperCase() + key.slice(1)}</Typography>}
            />
            <TextField
                id={`key-${index}`}
                label={`${key.charAt(0).toUpperCase() + key.slice(1)} Key`} // Capitalize the first letter
                variant="outlined"
                fullWidth
                color="secondary"
                margin="normal"
                value={showKeys ? value : value.replace(/./g, '•')} // Replace characters with dots if hideKeys is true
                InputProps={{ readOnly: true }}
                style={{ display: !!value ? 'block' : 'none', }} // Show/hide the text field based on checkbox state
                inputProps={{style: {fontSize: 13}}} 
            />
            </div>
        ))}
      </Box>

      <Box sx={{mt: 1, mb: 5}}> 
      <FormControlLabel
        control={<Switch color="secondary" size='small' checked={addRoyalties} onChange={handleAddRoyaltiesToggle} sx={{ml: 1}}/>}
        label={<Typography variant="body1" style={{ fontSize: '13px' }}>Add Royalties</Typography>}
      />
      {/* Add royalty field button */}
      {addRoyalties && (
        <IconButton color="secondary" onClick={handleAddRoyaltyField} disabled={royaltyFields.length >= 10}>
          <AddIcon />
        </IconButton>
      )}
      {/* Royalty Fields */}
      {addRoyalties && royaltyFields.map((field, index) => (
        <Grid container spacing={2} key={`royalty-field-${index}`} sx={{mt: .1}}>
          <Grid item xs={6} >
            <TextField
              label="Account ID"
              variant="outlined"
              fullWidth
              color="secondary"
              value={field.accountId}
              onChange={(event) => handleRoyaltyFieldChange(index, 'accountId', event.target.value)}
              inputProps={{style: {fontSize: 13}}}
              InputLabelProps={{style: {fontSize: 13}}}  
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Percentage"
              variant="outlined"
              fullWidth
              id="percent"
              color="secondary"
              value={field.percentage}
              onChange={(event) => handleRoyaltyFieldChange(index, 'percentage', event.target.value)}
              inputProps={{style: {fontSize: 13}}} 
              InputLabelProps={{style: {fontSize: 13}}} 
              type='number'
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleDeleteRoyaltyField(index)} disabled={royaltyFields.length === 1}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      </Box> 

      <Divider sx={{mt: 2, mb: 3}}></Divider>

      <Grid container spacing={2} sx={{mb: 2}}>
        <Grid item xs={12} sm={5} md={3}>
        <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        sx={{
            pl: 5,
            pr: 5,
            '&:hover': {
            backgroundColor: 'secondary.main',
            },
            width: "100%"
        }}
        onClick={async () => {
            const expirationTime = Timestamp.fromDate(new Date(Date.now() + 90 * 24 * 60 * 60 * 1000));

            const account = AccountId.fromString(currentUser ? currentUser : "");
            let createTokenTransaction = new TokenCreateTransaction()
              .setTokenName(tokenDetails.name)
              .setTokenSymbol(tokenDetails.symbol)
              .setTokenType(TokenType.NonFungibleUnique)
              .setTreasuryAccountId(account)
              // .setAutoRenewAccountId(account)
              // .setAutoRenewPeriod(8000000)
              .setSupplyKey(PrivateKey.fromString(keyValues.supply))
              .setSupplyType(TokenSupplyType.Finite)
              .setMaxSupply(parseInt(tokenDetails.supply))
              .setExpirationTime(expirationTime)

            // Array to hold all custom royalty fees
            let customFees = [];

            // Incorporate multiple custom royalty fees from state
            royaltyFields.forEach(field => {
            if (field.accountId && field.percentage) {
                console.log("ACCOUNT COLLECTION: ", field.accountId)
                const royaltyFee = new CustomRoyaltyFee()
                .setNumerator(parseInt(field.percentage))
                .setDenominator(100)  // Denominator is always 100
                //.setFallbackFee(new CustomFixedFee().setHbarAmount(new Hbar(1)))
                .setFeeCollectorAccountId(AccountId.fromString(field.accountId))
                customFees.push(royaltyFee);
            }
            });

            // Set custom fees if there are any valid entries
            if (customFees.length > 0) {
            createTokenTransaction.setCustomFees(customFees);
            }
   
            if(keyValues.admin){
                createTokenTransaction.setAdminKey(PrivateKey.fromString(keyValues.admin))
            }
            if(keyValues.freeze){
                createTokenTransaction.setFreezeKey(PrivateKey.fromString(keyValues.freeze))
            }
            if(keyValues.wipe){
                createTokenTransaction.setWipeKey(PrivateKey.fromString(keyValues.wipe))
            }
            if(keyValues.feeSchedule){
                createTokenTransaction.setFeeScheduleKey(PrivateKey.fromString(keyValues.feeSchedule))
            }
            if(keyValues.pause){
                createTokenTransaction.setPauseKey(PrivateKey.fromString(keyValues.pause))
            }
            if(keyValues.metadata){
              createTokenTransaction.setMetadataKey(PrivateKey.fromString(keyValues.metadata))
            }

            createTokenTransaction.setNodeAccountIds([AccountId.fromString("0.0.3")])
            createTokenTransaction.setTransactionId(TransactionId.generate(account));
            const frozenTransaction = createTokenTransaction.freeze();

            // Sign the frozen transaction with the admin key
            if (keyValues.admin) {
                frozenTransaction.sign(PrivateKey.fromString(keyValues.admin));
            }
    
            try {
                const executeResult = await executeTransaction(
                    account,
                    frozenTransaction
                );
                
                if(executeResult && executeResult.tokenId){
                    setTokenId("0.0." + executeResult.tokenId.num);
                      const keysInfo = [
                        { keyType: "admin", keySet: !!keyValues.admin },
                        { keyType: "freeze", keySet: !!keyValues.freeze },
                        { keyType: "wipe", keySet: !!keyValues.wipe },
                        { keyType: "fee", keySet: !!keyValues.feeSchedule },
                        { keyType: "pause", keySet: !!keyValues.pause },
                        { keyType: "supply", keySet: !!keyValues.supply },
                        { keyType: "metadata", keySet: !!keyValues.metadata }
                      ];
                  
                      const tokenData = {
                        tokenId: "0.0." + executeResult.tokenId.num,
                        tokenName: tokenDetails.name,
                        tokenSupply: tokenDetails.supply,
                        tokenSymbol: tokenDetails.symbol,
                        keys: keysInfo  // Adding the keys information
                      };
                
                    // Call update function
                    updateTokens(tokenData);
                }

                console.log({ executeResult });
              } catch(err) {
                  console.log(err)
              }
          }}
        >
        Create Token
        </Button>
        </Grid>
        <Grid item xs={12} sm={5} md={3}>       
        <Button 
        onClick={downloadKeys} 
        variant="outlined" 
        style={{color: "#fff", border: "solid 1px #5b00ff"}} 
        sx={{ width: "100%"}}>
            Download Keys
        </Button>
        </Grid> 
        {tokenId && (
            <>
            <Grid item xs={12}>
                <TextField
                    id={`tokenId`}
                    label={`Created Token ID`} // Capitalize the first letter
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    margin="normal"
                    value={tokenId ? tokenId : ""} // Replace characters with dots if hideKeys is true
                    InputProps={{ readOnly: true }}
                    inputProps={{style: {fontSize: 13}}} 
                />
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
                <Button 
                onClick={onTokenCreated} 
                variant="outlined" 
                style={{color: "#fff", border: "solid 1px #5b00ff"}} 
                sx={{ width: "100%"}}>
                    Mint Onto Token
                </Button>     
            </Grid>
            </>
        )}
       
      </Grid>
    </form>
  );
};

export default CreateTokenForm;
