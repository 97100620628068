import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Divider, 
    Typography, 
    Switch, 
    FormControlLabel, 
    Checkbox, 
    TextField, 
    Button, 
    IconButton, 
    Grid,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Paper,
    Tooltip
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { auth, db } from '../../services/firebase';
import { doc, updateDoc, collection, getDocs } from 'firebase/firestore';



const TokenTable = ({onTokenCreated, uploadId, handleKeyPass, handleTokenPass}) => {
  const [tokens, setTokens] = useState([]);
  const [currentUser, setCurrentUser] = useState(auth.currentUser ? auth.currentUser.uid : "");

  useEffect(() => {
    const fetchTokens = async () => {
      const tokensCollectionRef = collection(db, `users/${currentUser}/tokens`);

      try {
        const querySnapshot = await getDocs(tokensCollectionRef);
        const tokensList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTokens(tokensList);
        console.log("Fetched tokens:", tokensList);
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    };

    fetchTokens();
  }, []);

  const getIconForKeyType = (keyType) => {
    switch (keyType) {
      case "admin": return <AdminPanelSettingsIcon />;
      case "freeze": return <AcUnitIcon />;
      case "pause": return <PauseCircleFilledIcon />;
      case "wipe": return <DeleteSweepIcon />;
      case "fee": return <AttachMoneyIcon />;
      case "supply": return <AddCircleOutlineIcon />;
      default: return null;
    }
  };

  // Helper function to determine the color based on whether the key is set
  const getKeyColor = (isSet, keyType) => {
    const defaultColor = '#202136';  // Grey for unset keys
    if (!isSet) return defaultColor;

    switch (keyType) {
      case "admin": return '#ffcd36';  // Gold
      case "freeze": return '#7ac6ff';  // Light blue
      case "pause": return '#c15eff';  // Purple
      case "wipe": return '#ff3b6f';  // Red
      case "fee": return '#0fffbb'; 
      case "supply": return '#b0ff5c';  // Lime green
      default: return '#4caf50';  // Default green if somehow no type matches
    }
  };

return(
    <Grid item xs={12} sx={{mt: 2}}>
        <TableContainer component={Paper} >
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Token ID</TableCell>
                    <TableCell align="center">Token Name</TableCell>
                    <TableCell align="center">Token Supply</TableCell>
                    <TableCell align="right">Token Keys</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {tokens.map((token, index) => (
                    <TableRow key={index}>
                    <TableCell component="th" scope="row">
                        {token.tokenId}
                    </TableCell>
                    <TableCell align="center">{token.tokenName}</TableCell>
                    <TableCell align="center">{token.tokenSupply}</TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', padding: '16px' }}>
                        {token.keys.map((key, idx) => (
                        <Tooltip key={idx} title={key.keyType.charAt(0).toUpperCase() + key.keyType.slice(1)} placement="top">
                            <IconButton 
                            aria-label={`${key.keyType} key`} 
                            sx={{ color: getKeyColor(key.keySet, key.keyType), mx: 0.5 }}>
                            {getIconForKeyType(key.keyType)}
                            </IconButton>
                        </Tooltip>
                        ))}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
  );
};

export default TokenTable;
