export const generateRandomFileName = () => {
    // Get the current timestamp
    const timestamp = new Date().getTime();
  
    // Generate a random unique identifier (UUID)
    const uuid = Math.random().toString(36).substring(2) + Date.now().toString(36);
  
    // Combine timestamp and UUID to create a unique file name
    const fileName = `${timestamp}_${uuid}`;
  
    return fileName;
  };

// Utility function to convert URLs within text into clickable links
export const contentWithLinks = (text) => {
    // Improved URL matching regex pattern
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  
    // Finding all matches along with their indices
    const urls = [...text.matchAll(urlRegex)];
    const parts = [];
  
    if (!urls.length) {
      // No URLs, just return the original text
      return [text];
    }
  
    let lastIndex = 0;
    urls.forEach((match) => {
      const url = match[0];
      const index = match.index;
  
      // Adding text before URL
      if (index > lastIndex) {
        parts.push(text.substring(lastIndex, index));
      }
  
      // Adding URL as a link
      parts.push(
        <a href={url} key={url} target="_blank" rel="noopener noreferrer" style={{color: '#1976d2'}}>
          {url}
        </a>
      );
  
      lastIndex = index + url.length;
    });
  
    // Adding any remaining text after the last URL
    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }
  
    return parts;
};


//GET STRING TRANSACTION ID OUT OF TRANSID OBJECT
export const extractTransactionId = (transactionIdObject) => {

  const accountId = "0.0." + transactionIdObject.accountId.num.low;
  const seconds = transactionIdObject.validStart.seconds.low;
  const nanoseconds = transactionIdObject.validStart.nanos.low;
  const transactionId = accountId + "@" + seconds + "." + nanoseconds;

  return transactionId;
}

export const extractTransactionIdLink = (transactionIdObject) => {

  const seconds = transactionIdObject.validStart.seconds.low;
  const nanoseconds = transactionIdObject.validStart.nanos.low;
  const transactionId = seconds + "." + nanoseconds;

  return transactionId;
}