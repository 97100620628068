
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { functions, httpsCallable, db, auth } from '../firebase'; 
import { doc, getDoc, collection, query, orderBy, limit, getDocs } from "firebase/firestore";

const {
  PublicKey
} = require("@hashgraph/sdk");

export const verifyAndCreateToken = async (signedMessage, walletAddress) => {
    
    const account = signedMessage[0].accountId;
    const signedData = signedMessage[0].signature;
    const publicKeyData = signedMessage[0].publicKey;

    const publicKeyBytes = new Uint8Array(publicKeyData._key._key._keyData);
    const publicKeyString = Array.from(publicKeyBytes).map(byte => byte.toString(16).padStart(2, '0')).join('');

    // const messageString = prefixMessageToSign("OpenPlot.io");
    // const messageBytes = new TextEncoder().encode(messageString);
    
    // const isSignatureValid = publicKeyData.verify(messageBytes, signedData);
    
    // console.log("Signature verification result:", isSignatureValid);

    // function prefixMessageToSign(message) {
    //   return '\x19Hedera Signed Message:\n' + message.length + message;
    // }   
    
    // const verifyUser = await hc.verifyMessageSignature(message, signedMessage, publicKeyData);

    // if(!verifyUser){
    //   return(false);
    // }else{
    //   const getAccessToken = httpsCallable(functions, 'getAccessToken');
    //   const signedToken = await getAccessToken({ accountId: account, signature: signedData, publicKey: publicKey })
    //     .then((result) => {
    //         return result.data;
    //     });

    //   return(signedToken)
    // }

    const getAccessToken = httpsCallable(functions, 'getAccessToken');
    const signedToken = await getAccessToken({ accountId: account, signature: signedData, publicKey: publicKeyString })
      .then((result) => {
          return result.data;
      });
        
    return(signedToken)

};

export const getMediaURL = async (Link) => {

  const storage = getStorage();
  const URL = getDownloadURL(storageRef(storage, Link))
      .then((url) => {
        return url;
      })
      .catch((error) => {
        return error;
      });

  return(URL);  

}

export const fetchUserData = async (account) => {
  if(auth.currentUser){
    const userDocRef = doc(db, "users", account);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return(userData)
    } else {
      return({error: "No user found"})
    }
  }
};

export const fetchTopUsers = async () => {
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, orderBy('followerCount', 'desc'), limit(25));

  try {
    const querySnapshot = await getDocs(q);
    const topUsers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return topUsers;
  } catch (error) {
    console.error('Error fetching top users:', error);
    return [];
  }
};


