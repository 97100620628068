import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../../contexts/AuthContext';
import { AppStore } from '../../store';

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const { isConnected } = useSelector((state: AppStore) => state.hashconnect);

  if (!currentUser || !isConnected) {
    // Redirect to the login page or any other page if the user is not authenticated or not connected to a wallet
    return <Navigate to="/" />;
  }

  return <Outlet />; // Render child routes if the user is authenticated and connected
};

export default PrivateRoute;