import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    blurple: Palette['primary'];
    amber: Palette['primary'];
    deepPurple: Palette['primary'];
    teal: Palette['primary'];
    white: Palette['primary'];
    black: Palette['primary'];
    lime: Palette['primary'];
    danger: Palette['primary'];
    success: Palette['primary'];
    info: Palette['primary'];
    warning: Palette['primary'];
    lightbg: Palette['primary'];
  }
  interface PaletteOptions {
    blurple?: PaletteOptions['primary'];
    amber?: PaletteOptions['primary'];
    deepPurple?: PaletteOptions['primary'];
    teal?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
    lime?: PaletteOptions['primary'];
    danger?: PaletteOptions['primary'];
    success?: PaletteOptions['primary'];
    info?: PaletteOptions['primary'];
    warning?: PaletteOptions['primary'];
    lightbg?: PaletteOptions['primary'];
  }
}

// const themeOptions: ThemeOptions = {
//   typography: {
//     h1: {
//       fontSize: "3rem",
//     },
//     h2: {
//       fontSize: "2.5rem",
//     },
//     h3: {
//       fontSize: "2rem",
//     },
//     h4: {
//       fontSize: "1.75rem",
//     },
//     h5: {
//       fontSize: "1.5rem",
//     },
//     h6: {
//       fontSize: "1.25rem",
//     },
//     subtitle1: {
//       fontSize: "0.9rem",
//     },
//     subtitle2: {
//       fontSize: "0.8rem",
//     },
//   },
//   palette: {
//     mode: "dark",
//     primary: {
//       main: "#000000",
//     },
//     secondary: {
//       main: "#252836",
//     },
//     text: {
//       primary: "#FFFFFF",
//     },
//     blurple: {
//       main: "#525298",
//     },
//     amber: {
//       main: "#FFC107", // Amber
//     },
//     deepPurple: {
//       main: "#673AB7", // Deep Purple
//     },
//     teal: {
//       main: "#00ffe1", // Teal
//     },
//     white: {
//       main: "#ffffff", // Teal
//     },
//     black: {
//       main: "#000000", // Teal
//     },
//     lime: {
//       main: "#CDDC39", // Lime
//     },
//     danger: {
//       main: "#e04646", // Red
//     },
//     success: {
//       main: "#4CAF50", // Success
//     },
//     info: {
//       main: "#2196F3", // Info
//     },
//     lightbg: {
//       main: "#2b2c2e", // Light
//     },
//     warning: {
//       main: "#FF9800", // Warning
//     },
//     background: {
//       paper: "#000000",
//     },
//     error: {
//       main: red.A400,
//     },
//   },
// };

const themeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#000000",
          backgroundImage: `linear-gradient(19deg, #000000 0%, #DDD6F3 100%)`,
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
    subtitle1: {
      fontSize: "0.9rem",
    },
    subtitle2: {
      fontSize: "0.8rem",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#5d00ff",
    },
    text: {
      primary: "#FFFFFF",
    },
    blurple: {
      main: "#181e2b",
    },
    amber: {
      main: "#FFC107", // Amber
    },
    deepPurple: {
      main: "#151728", // Deep Purple
    },
    teal: {
      main: "#00ffe1", // Teal
    },
    white: {
      main: "#ffffff", // Teal
    },
    black: {
      main: "#000000", // Teal
    },
    lime: {
      main: "#CDDC39", // Lime
    },
    danger: {
      main: "#e04646", // Red
    },
    success: {
      main: "#00ff88", // Success
    },
    info: {
      main: "#2196F3", // Info
    },
    lightbg: {
      main: "#080114", // Light
    },
    warning: {
      main: "#ffa81c", // Warning
    },
    background: {
      paper: "#070812",
    },
    error: {
      main: red.A400,
    },
  },
};

// A custom theme for this app
const theme = createTheme(themeOptions);

export const ThemeProvider = (props: { children?: React.ReactNode }) => {
  return <MuiThemeProvider {...props} theme={theme} />;
};
