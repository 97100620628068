import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Box p={0}>
      <Outlet />
    </Box>
  );
};
