import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Link, IconButton, Drawer, 
List, ListItem, ListItemText, useMediaQuery, useTheme, Button, Typography, Divider} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // For the hamburger icon
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { HashConnectConnectButton } from "../hashconnect/hashconnect-client";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import WalletButton from './WalletButton';

export const Navbar = () => {
  const theme = useTheme();
  const auth = getAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // !! converts truthy/falsy values to boolean true/false
    });

    return () => unsubscribe(); // Clean up subscription
  }, [auth]);

  const menuItems = [
    { text: 'Inscribe', link: '/' },
  ];
  

  return (
    <AppBar position="sticky" color="secondary">
      <Toolbar>
        <Link to="/" component={RouterLink} underline="none" sx={{mt: 0.2}}>
          <img src="/openplot_logo_white.png" alt="Hashinals.io" style={{ height: '23px' }} />
        </Link>

        {/* Navigation Buttons to the left of the connect button */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
          {!isMobile && menuItems.map((item, index) => (
            <Button key={index} to={item.link} component={RouterLink} color="inherit" sx={{ mx: 1 }}>
              {item.text}
            </Button>
          ))}
        </Box>

        {/* {isAuthenticated ? (
          <WalletButton />
        ):(<></>)} */}
      
        {/* Connect Button */}
        <Box sx={{ flexGrow: 0, ml: 'auto', mr: .5 }}>
          <HashConnectConnectButton />
        </Box>

        {/* Mobile: Hamburger Menu */}
        {/* {isMobile && (
          <>
            <IconButton color="inherit" onClick={() => setDrawerOpen(true)} sx={{ ml: 2 }}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <Box
                sx={{
                  padding: theme.spacing(2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  bgcolor: 'primary.main',
                  color: 'white'
                }}
              >
                <img src='/openplot_symbol_white.png' height="40" />
              </Box>
              <Divider />
        
              <List sx={{ width: 250, p: 2 }}>
                {menuItems.map((item, index) => (
                  <ListItem
                  button
                  key={index}
                  component={RouterLink}
                  to={item.link}
                  onClick={() => setDrawerOpen(false)}
                  selected={item.link === location.pathname}
                  sx={{
                    '&.Mui-selected': {
                      bgcolor: 'primary.light', // Custom background color for selected item
                      color: 'primary.contrastText',
                      '& .MuiListItemIcon-root': {
                        color: 'inherit', // Ensures the icon color matches the text
                      },
                    },
                  }}
                >
                  
                    <ListItemText primary={item.text} />
             
                </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        )} */}
      </Toolbar>
    </AppBar>
  );
};