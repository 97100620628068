import React, { useState, useEffect } from 'react';
import axios from 'axios';
//MUI
import { Paper, Box, CircularProgress, Typography, LinearProgress, Chip, Grid, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams, Navigate, Link, useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//COMPONENTS
import { GLBPreview } from '../components/parts/GLBPreview';

//FIREBASE
import { auth, db } from '../services/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

import {
  hc,
} from "../services/hashconnect";
import { 
  AccountId,
  TransferTransaction,
} from '@hashgraph/sdk';

import MintForms from '../components/parts/MintForms';


export const Inscription = () => {
    const { uploadId } = useParams();
    const [uploadDetails, setUploadDetails] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        const uploadDocRef = doc(db, `uploads/${uploadId}`);
        const unsubscribe = onSnapshot(uploadDocRef, (snapshot) => {
        if (snapshot.exists()) {
            setUploadDetails(snapshot.data());
            setUploadProgress(parseInt(snapshot.data().progress))
        } else {
            console.log("Upload document not found!");
        }
        });

        return () => unsubscribe();
    }, [uploadId]);

    const statusColors = {
        pending: '#FFD700', // Yellow
        confirmed: '#FFA500', // Orange
        queued: '#4169E1', // Blue
        processing: '#8A2BE2', // Orange
        completed: '#32CD32' // Green
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
  
    return (
        <div style={{ padding: '20px' }}>
          {uploadDetails ? (
            <>
            <Paper elevation={3} style={{ marginBottom: "20px", padding: '20px', position: 'relative', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
              <LinearProgress color="success" variant="determinate" value={uploadProgress} style={{ position: 'absolute', top: 0, left: 0, right: 0, }} />
              
              <Grid container alignItems="center" justifyContent="space-between">
                <Chip
                    variant='outlined'
                    sx={{
                        border: `solid 1px ${statusColors[uploadDetails.status.toLowerCase()]}`,
                        color: '#fff' // Text color
                    }}
                    label={`
                    ${capitalizeFirstLetter(uploadDetails.status === "completed" ? "Inscription Complete" : uploadDetails.status)}
                    ${uploadDetails.status === "processing" ? ` - ` + uploadDetails.progress + `%` : ""}`
                    }
                />                
                <Typography sx={{color: "rgba(150, 150, 150, .8)", fontSize: 14}}>uid:{uploadId}</Typography>
              </Grid>
             <Divider sx={{mt: 2, mb: 2}}></Divider>
             {uploadDetails.fileOnly === true ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none', fontSize: 14 }}>
                  <Chip
                    label="HCS-1 FILE"
                    variant="outlined"
                    sx={{ color: 'secondary', borderColor: '#5d00ff', mr: 1 }}  // Margin right to space out the chip from the text
                  />
                  <Typography sx={{ color: 'white', textDecoration: 'none', fontSize: 14 }}>
                    Topic ID: <span style={{ marginLeft: "5px" }}></span>
                    <a target='_blank' style={{ color: '#e3e3e3', textDecoration: 'none', ml: 1 }} href={`https://tier.bot/api/hashinals-cdn/${uploadDetails.mediaTopicId}?network=${process.env.REACT_APP_NETWORK}`}>
                        {uploadDetails.mediaTopicId} <OpenInNewIcon fontSize='xs' sx={{pt: 0.5}}/>
                    </a>
                  </Typography>
                </Box>
              </>
             ):(
              <>
              <Typography sx={{ color: 'white', textDecoration: 'none', fontSize: 14 }}>
                File Topic ID: <span style={{ marginLeft: "5px" }}></span>
                <a target='_blank' style={{ color: '#e3e3e3', textDecoration: 'none', ml: 1 }} href={`https://tier.bot/api/hashinals-cdn/${uploadDetails.mediaTopicId}?network=${process.env.REACT_APP_NETWORK}`}>
                    {uploadDetails.mediaTopicId} <OpenInNewIcon fontSize='xs' sx={{pt: 0.5}}/>
                </a>
              </Typography>           
              <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 14 }}>
                Metadata Topic ID: <span style={{ marginLeft: "5px" }}></span>
                <a target='_blank' style={{ color: '#e3e3e3', textDecoration: 'none', ml: 1 }} href={`https://tier.bot/api/hashinals-cdn/${uploadDetails.metadataTopicId}?network=${process.env.REACT_APP_NETWORK}`}>
                    {uploadDetails.metadataTopicId} <OpenInNewIcon fontSize='xs' sx={{pt: 0.5}}/>
                </a>
              </Typography>
              <Divider sx={{mt: 2, mb: 2}}></Divider>

              </>
             )}
          
              {uploadDetails.fileOnly === false && (
                <>
                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, mb: 1.5, fontSize: 14, fontWeight: "bold" }}>
                    METADATA
                </Typography>

                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12, }}>
                  <b>Name:</b> <span style={{ marginLeft: "2px" }}></span>
                      {uploadDetails.name ? uploadDetails.name : ""} 
                </Typography>
                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Creator:</b> <span style={{ marginLeft: "2px" }}></span>
                      {uploadDetails.creator ? uploadDetails.creator : ""} 
                </Typography>
                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Description:</b> <span style={{ marginLeft: "2px" }}></span>
                      {uploadDetails.description ? uploadDetails.description : ""} 
                </Typography>
                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Type:</b> <span style={{ marginLeft: "2px" }}></span>
                      {uploadDetails.type ? uploadDetails.type : ""} 
                </Typography>

                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Properties:</b> <span style={{ marginLeft: "2px" }}></span>
                  {uploadDetails.properties && uploadDetails.properties.length > 0 ?
                    uploadDetails.properties.map((property, index) => (
                      <Chip variant='outlined' color="secondary" key={index} label={`${property.key}: ${property.value}`} sx={{ margin: '2px', color: "#FFF", fontSize: "10px"  }} />
                    )) : "None"
                  }
                </Typography>

                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Attributes:</b> <span style={{ marginLeft: "2px" }}></span>
                  {uploadDetails.attributes && uploadDetails.attributes.length > 0 ?
                    uploadDetails.attributes.map((attribute, index) => (
                      <Chip  variant='outlined' color="info" key={index} label={`${attribute.trait_type}: ${attribute.value}`} sx={{ margin: '2px', color: "#FFF", fontSize: "10px" }} />
                    )) : "None"
                  }
                </Typography>

                <Typography sx={{ color: 'white', textDecoration: 'none', mt: 1, fontSize: 12 }}>
                  <b>Additional Files:</b> <span style={{ marginLeft: "2px" }}></span>
                  {uploadDetails.additionalFiles && uploadDetails.additionalFiles.length > 0 ?
                    uploadDetails.additionalFiles.map((file, index) => (
                      <Chip  variant='outlined' color="warning" key={index} label={file.uri } sx={{ margin: '2px', color: "#FFF", fontSize: "10px"  }} />
                    )) : "None"
                  }
                </Typography>
                </>
              )}      
              

              <Divider sx={{mt: 2, mb: 2}}></Divider>

              
    
            </Paper>

            {uploadDetails.fileOnly === false && uploadDetails.status === "completed" && ( 
               
            <MintForms uploadId={uploadId} metadataTopicId={uploadDetails.metadataTopicId} />
            )}

            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      );
  };
