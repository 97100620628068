// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore"; // Import firestore functions
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FieldValue } from "firebase/firestore"; // Import FieldValue
import { getDatabase } from "firebase/database";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const realDb = getDatabase(app); // Initialize Realtime Database


export { auth, db, realDb, storage, functions, httpsCallable, signInWithCustomToken };

// Function to update user presence
export const updateUserPresence = (userId, isOnline) => {
  const userStatusRef = doc(db, `users/${userId}`);
  return setDoc(userStatusRef, {
    isOnline: isOnline,
    lastChanged: (FieldValue as any).serverTimestamp(), // Cast FieldValue to any
  }, { merge: true });
};

// Function to monitor user activity
export const monitorUserActivity = () => {
  auth.onAuthStateChanged(user => {
    if (user) {
      const userId = user.uid;
      updateUserPresence(userId, true);

      // Set user as offline when they log out or close the window
      window.addEventListener('beforeunload', () => {
        updateUserPresence(userId, false);
      });
    }
  });
};